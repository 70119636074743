<template>
  <div>
    <div class="logs-tool-container">
      <el-form :inline="true" size="mini" class="logs-tool-form">
        <el-form-item :label="$t('logStartTime')">
          <el-date-picker v-model="sinceTime" type="datetime" value-format="timestamp" @change="log"> </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('tailLines')">
          <el-select v-model="tailLines" style="width: 100px" @change="log">
            <el-option :label="$t('unit.all')" :value="-1"></el-option>

            <el-option :label="'500' + $t('unit.line')" :value="500"></el-option>
            <el-option :label="'1000' + $t('unit.line')" :value="1000"></el-option>
            <el-option :label="'2000' + $t('unit.line')" :value="2000"></el-option>
            <el-option :label="'5000' + $t('unit.line')" :value="5000"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('prePodLogs')">
          <el-switch v-model="previous" @change="log"> </el-switch>
        </el-form-item>

        <el-form-item :label="$t('handle.search')" style="float: right">
          <el-input v-model="searchText" @keyup.enter.native="search"> </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div id="logs"></div>
  </div>
</template>

<script>
import { Terminal } from "xterm";
import "xterm/css/xterm.css";
import { FitAddon } from "xterm-addon-fit";
import { SearchAddon } from "xterm-addon-search";
export default {
  name: "Logs",
  data() {
    return {
      logSocket: null,
      previous: false,
      sinceTime: undefined,
      term: null,
      tailLines: 500,
      searchText: "",
      searchAddon: null
    };
  },

  computed: {
    vendor() {
      return this.$route.query.vendor;
    },

    region() {
      return this.$route.query.region;
    },

    cluster() {
      return this.$route.query.cluster;
    },

    namespace() {
      return this.$route.query.namespace;
    },

    token() {
      return this.$store.state.app.token;
    },

    userName() {
      return this.$store.state.app.userName;
    },

    userId() {
      return this.$store.state.app.userId;
    },

    organization() {
      return this.$store.state.app.organization;
    }
  },

  methods: {
    async initXterm() {
      this.term = new Terminal({
        scrollback: 10000,
        theme: {
          background: "#002240"
        }
      });

      let fitAddon = new FitAddon();
      this.searchAddon = new SearchAddon();
      this.term.loadAddon(fitAddon);
      this.term.loadAddon(this.searchAddon);
      this.term.open(document.getElementById("logs"));
      fitAddon.fit();

      return this.term;
    },

    search() {
      this.searchAddon.findNext(this.searchText);
    },

    log() {
      this.term.clear();

      if (this.logSocket) this.logSocket.close();

      let wsProtocol = window.location.protocol === "http:" ? "ws://" : "wss://";
      let pod = this.$route.params.name;
      let container = this.$route.params.container;

      let params = `X-KubeStar-Token=${this.token}&X-KubeStar-UserID=${this.userId}&X-KubeStar-UserName=${this.userId}&previous=${this.previous}&tailLines=${this.tailLines}&X-KubeStar-Organization=${this.organization}`;

      if (this.sinceTime) params += `&sinceTime=${this.sinceTime}`;

      let url = `${wsProtocol}${window.location.host}/api/v1/k8s.kubestar.io/vendors/${this.vendor}/regions/${this.region}/clusters/${this.cluster}/namespaces/${this.namespace}/pods/${pod}/containers/${container}/logstream?${params}`;

      this.logSocket = new WebSocket(url);

      this.logSocket.onmessage = ev => {
        this.term.writeln(ev.data.replace(/\n/g, "\n\r"));
      };

      this.logSocket.onerror = () => {
        this.term.writeln(`[Connent Error]: Error`);
      };
    }
  },

  async mounted() {
    await this.initXterm();
    this.log();
  },

  beforeDestroy() {
    if (this.logSocket) this.logSocket.close();
    this.logSocket = null;
  }
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

#logs {
  width: 100%;
  height: calc(100vh - 50px);
  background: #002240;
  padding: 20px;
  box-sizing: border-box;

  .xterm {
    height: 100%;
  }
}

.logs-tool-container {
  background: #002240;

  border-bottom: 1px solid #000;
  padding: 10px;
}

.logs-tool-form {
  height: 30px;
  .el-form-item__label {
    color: #fff;
  }

  .el-form-item {
    margin-right: 30px;
  }
}
</style>
